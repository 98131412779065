<template>
  <div class="background">
    <ApiKeys />
  </div>
</template>

<script>
import ApiKeys from "@/components/elements/dashboard/api-keys/ApiKeys";

export default {
  name: "DocumentManagementApiKeys",

  components: {
    ApiKeys,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
